import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import {
  ApolloProvider,
} from "@apollo/client";

import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import Slide from '@mui/material/Slide';

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { Navigation } from './components/Navigation'
import { AuthHandlerComponent, AuthenticationRequired, OtpLinkLogin, UpdateMyPasswordComponent } from './components/Authentication'
import { MeProvider } from './providers/Me'
import { PatientListing, PatientRegistration, PatientChecksumVerification } from "./components/Patients";
import { QueryListing, QueryDetails } from "./components/Queries";
import { ImpersonateUser, OperationsPage, SiteClosureReport } from "./components/Operations";
import { PatientActivityListing } from "./components/PatientActivities";
import { Help, StudyDocuments, Videos, UserDownloads } from "./components/Help";
import { DashboardScreen } from "./components/Dashboard";
import { PatientReport } from "./components/PatientReport";
import { MyThemeProvider } from "./providers/MyThemeProvider";

import { client } from './apollo';


const ThemedSnackbarProvider = props => (
  <SnackbarProvider {...props} sx={{
    '& .SnackbarItem-variantSuccess': {
      backgroundColor: 'success.main',
      color: 'success.contrastText',
    },
    '& .SnackbarItem-variantError': {
      backgroundColor: 'error.main',
      color: 'error.contrastText',
    },
    '& .SnackbarItem-variantWarning': {
      backgroundColor: 'warning.main',
      color: 'warning.contrastText',
    },
    '& .SnackbarItem-variantInfo': {
      backgroundColor: 'info.main',
      color: 'info.contrastText',
    },
  }} />
)

export const App = () => {
  return (
    <>
      <ApolloProvider client={client}>
        <MyThemeProvider>
          <CssBaseline />
          <Router>
            <Switch>
              <Route path="/auth/:token"><AuthHandlerComponent /></Route>
              <Route exact path="/user/reset/:email/:expiresOn/:userHash"><OtpLinkLogin /></Route>
              <Route exact path="/site-closure/:id"><SiteClosureReport /></Route>

              <AuthenticationRequired>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MeProvider>
                    <ThemedSnackbarProvider
                      maxSnack={5}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      TransitionComponent={Slide}
                    >
                      <Navigation>
                        <Route exact path="/dashboard"><DashboardScreen /></Route>
                        <Route exact path="/registration"><PatientRegistration /></Route>
                        <Route exact path="/patients"><PatientListing /></Route>
                        <Route path="/patient/:ssid"><PatientChecksumVerification /></Route>
                        <Route exact path="/activities"><PatientActivityListing /></Route>

                        <Route exact path="/patient-reports">
                          <PatientReport />
                        </Route>
                        
                        {/**
                          <Route exact path="/patient/:ssid/query"><QueryCreatorComponent /></Route>
                        */}

                        <Route exact path="/my/password"><UpdateMyPasswordComponent /></Route>

                        <Route exact path="/documents"><StudyDocuments /></Route>
                        <Route exact path="/user-downloads"><UserDownloads /></Route>

                        <Route exact path="/queries"><QueryListing /></Route>
                        <Route exact path="/query/:id"><QueryDetails /></Route>
                        <Route path="/operations"><OperationsPage /></Route>
                        <Route exact path="/users/impersonate"><ImpersonateUser /></Route>
                        <Route exact path="/help"><Help /></Route>
                        <Route exact path="/videos"><Videos /></Route>
                      </Navigation>
                    </ThemedSnackbarProvider>
                  </MeProvider>
                </LocalizationProvider>
              </AuthenticationRequired>
            </Switch>
          </Router>
        </MyThemeProvider>
      </ApolloProvider>
    </>
  );
}

export default App;
