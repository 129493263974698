import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
} from "@apollo/client";

import {
  createUploadLink,
} from 'apollo-upload-client';

import { Settings } from './Settings'

const uploadLink = createUploadLink({
  uri: Settings.GRAPHQL_URI,
})

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(Settings.ACCESS_TOKEN);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    }
  })
  return forward(operation);
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, uploadLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      // errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      // errorPolicy: 'ignore',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      // errorPolicy: 'ignore',
    }
  }
})
